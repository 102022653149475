<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="4">
        <div class="center-logo">
          <app-logo></app-logo>
        </div>
        <v-card flat class="rounded-xl">
          <v-toolbar flat class="d-flex justify-center pt-2">
            <v-card-title class="bold-text capitalize">
              reset your password
            </v-card-title>
          </v-toolbar>
          <v-card-subtitle class="px-13">
            Enter your email and we will send you a link to reset your password.
          </v-card-subtitle>
          <v-form lazy-validation ref="form" class="text-left px-10 pb-7">
            <label class="pl-4">Email</label>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="secondary"
              :rules="validate.required('email')"
              placeholder="Email"
              type="email"
              v-model="form.email"
            >
              <template v-slot:prepend-inner>
                <v-icon class="pa-2 ml-n6 rounded-xl primary" color="white">
                  mdi-email-outline
                </v-icon>
              </template>
            </v-text-field>
            <div class="justify-center d-flex pb-4">
              <v-btn
                text
                class="rounded-xl px-7 py-5 primary capitalize"
                @click="$refs.form.validate() ? sendPasswordResetLink() : null"
                :loading="loading"
              >
                send password reset link
              </v-btn>
            </div>
            <v-row>
              <v-card-text @click="goBack" class="text-center pa-4 pointer">
                <v-icon>mdi-keyboard-backspace</v-icon>
                Go back
              </v-card-text>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import AppLogo from "../../components/Logo";
import FormValidation from "../../utils/FormValidation";
import ResponseHelper from "../../utils/ResponseHelper";
export default {
  name: "ForgotPassword",
  components: {
    AppLogo,
  },
  data() {
    return {
      form: {},
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      loading: false,
    };
  },
  methods: {
    ...mapActions("auth", ["forgot_password"]),
    async sendPasswordResetLink() {
      this.loading = true;
      try {
        const response = await this.forgot_password(this.form);
        this.loading = false;
        this.toast.sendSuccess(response);
      } catch (error) {
        this.loading = false;
        this.toast.sendError(error);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.center-logo {
  display: inline-flex;
}
.bold-text {
  font-weight: 900;
}
</style>
